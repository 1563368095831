import React from 'react';

import { container, label, button, active } from './column-filter.module.scss';
import {useI18next} from "../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

interface IColumnFilter {
    className?: string;
    config: IColumnConfig;
    handleClick: (columns: number, isWide: boolean) => void;
}

export interface IColumnConfig {
    columns: number;
    isWide: boolean;
}

const ColumnFilter: React.FC<IColumnFilter> = ({ className = '', config, handleClick }) => {
    const { t } = useI18next();
    return (
        <div className={`${container} ${className}`}>
            <span className={label}>{t('column.filter.view')}</span>
            {filters.map(({ columns, isWide }, index) => (
                <button
                    key={`column-filter-column-button-${index}`}
                    className={`${button} ${columns === config.columns ? active : ''}`}
                    onClick={() => handleClick(columns, isWide)}
                >
                    {columns}
                </button>
            ))}
        </div>
    );
};

const filters = [
    {
        columns: 2,
        isWide: true,
    },
    {
        columns: 4,
        isWide: false,
    },
];

export default ColumnFilter;
