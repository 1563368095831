import React, { useState } from 'react';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { graphql, GetServerDataProps } from 'gatsby';

import { layout, listingFilter, columnFilter, categoryFilter } from './recipe-listing.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { IRecipe } from '../models/recipe.model';
import { ISitePageContext } from '../models/site-page.model';
import { IRecipeCategory, TRecipeCategoryBusinessMeaning } from '../models/recipe-category.model';
import { IMeta } from '../models/meta.model';
import { IBreadcrumbsProps } from '../models/breadcrumbs.model';

import { isBrowser } from '../utils/is-browser';
import { getNodes } from '../utils/get-nodes';
import { fetchRecipes } from '../api-ssr/recipes';
import { createFilterObjectFromQuery } from '../api-ssr/utility/filters';

import MainLayout from '../layouts/main-layout';
import Listing from '../components/organisms/listing';
import RecipeCard from '../components/molecules/recipe-card';
import BlockHeader from '../components/molecules/block-header';
import CategoryFilter from '../components/molecules/category-filter';
import ColumnFilter, { IColumnConfig } from '../components/molecules/column-filter';

interface IRecipeListing {
    readonly data: {
        allStrapiRecipe: IQueryAllResult<IRecipe>;
        allStrapiRecipeCategory: IQueryAllResult<IRecipeCategory>;
        meta: IMeta;
        pageBreadcrumbs: {
            breadcrumbs: IBreadcrumbsProps[];
        };
        currentRootCategory: IRecipeCategory;
    };
    readonly pageContext: ISitePageContext;
    serverData: {
        recipes: IRecipe[];
        showRecipesFromServerData: boolean;
    };
}

const RecipeListing: React.FC<IRecipeListing> = ({
    data,
    pageContext,
    serverData,
}: IRecipeListing) => {
    const { allStrapiRecipe, allStrapiRecipeCategory, meta, currentRootCategory } = data;
    const { recipes: filteredRecipes, showRecipesFromServerData } = serverData;
    const { paginationKeys } = pageContext;
    const { t, pageKey } = useI18next();
    const activeIndex = paginationKeys.indexOf(pageKey);

    const recipes = showRecipesFromServerData ? filteredRecipes : getNodes(allStrapiRecipe);
    const categories = getNodes(allStrapiRecipeCategory).filter(
        (category) => category.recipes && category.recipes.length > 0
    );
    const page = { meta };

    const [listingConfig, setListingConfig] = useState<IColumnConfig>(
        getListingConfigByCategory(currentRootCategory)
    );

    function handleClick(columns: number, wide: boolean) {
        setListingConfig({ columns: columns, isWide: wide });
        window.localStorage.setItem(
            'listingConfig',
            JSON.stringify({ columns: columns, isWide: wide })
        );
    }

    return (
        <MainLayout
            className={layout}
            page={page}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            showLocalizationBar={true}
            breadcrumbs={data.pageBreadcrumbs.breadcrumbs}
        >
            <BlockHeader {...setTitleText(t, pageContext.slug)} />
            <div className={listingFilter}>
                <CategoryFilter className={categoryFilter} categories={categories} />
                <ColumnFilter
                    className={columnFilter}
                    config={listingConfig}
                    handleClick={handleClick}
                />
            </div>
            <Listing
                largeGaps={true}
                columns={listingConfig.columns}
                ItemComponent={RecipeCard}
                data={recipes}
                paginationConfig={{
                    links: paginationKeys.map((key) => {
                        return { to: key };
                    }),
                    activeIndex: activeIndex,
                }}
                itemIdKey="recipeId"
                itemProps={{
                    fullWidth: listingConfig.isWide,
                    asGatsbyImage: !showRecipesFromServerData,
                }}
            />
        </MainLayout>
    );
};

const getListingConfigByCategory = (category: IRecipeCategory) => {
    const rootCategoryBusinessMeaning = category.businessMeaning;

    let args: Parameters<typeof getListingConfig> | undefined = [];

    if (rootCategoryBusinessMeaning && customColumnsNumber[rootCategoryBusinessMeaning]) {
        args = [customColumnsNumber[rootCategoryBusinessMeaning], true, true];
    }

    return getListingConfig(...args);
};

const customColumnsNumber: Partial<Record<TRecipeCategoryBusinessMeaning, number>> = {
    mulled: 2,
};

const getListingConfig = (columns = 4, forceDefault = false, isWide = false) => {
    const defaultPreset: IColumnConfig = { columns, isWide: isWide };

    if (!isBrowser() || forceDefault) {
        return defaultPreset;
    }

    const preset = window.localStorage.getItem('listingConfig');

    if (!preset) {
        return defaultPreset;
    }

    return JSON.parse(preset);
};

function setTitleText(t: ReturnType<typeof useI18next>['t'], slug: string) {
    switch (slug) {
        case 'drinki':
        case 'drinks':
            return {
                titleText: t('recipe.listing.title.drinks'),
                subtitleText: t('recipe.listing.subtitle.drinks'),
            };
        case 'desery':
        case 'desserts':
            return {
                titleText: t('recipe.listing.title.desserts'),
                subtitleText: t('recipe.listing.subtitle.desserts'),
            };
        case 'grzance':
        case 'mulled':
            return {
                titleText: t('recipe.listing.title.mulled'),
                subtitleText: t('recipe.listing.subtitle.mulled'),
            };
        case 'shoty':
        case 'shots':
            return {
                titleText: t('recipe.listing.title.shots'),
                subtitleText: t('recipe.listing.subtitle.shots'),
            };
        case 'kawy':
        case 'coffees':
            return {
                titleText: t('recipe.listing.title.caffe'),
                subtitleText: t('recipe.listing.subtitle.caffe'),
            };
        default:
            return {
                titleText: t('recipe.listing.title'),
                subtitleText: t('recipe.listing.subtitle'),
            };
    }
}

export const query = graphql`
    query (
        $language: String!
        $recipeId: [Int]
        $recipeCategoryId: Int
        $metaId: Int
        $path: String
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        pageBreadcrumbs: sitePage(path: { eq: $path }) {
            breadcrumbs {
                label
                slug
            }
        }
        allStrapiRecipe(
            filter: { recipeId: { in: $recipeId }, locale: { eq: $language } }
            sort: { fields: [sequence, published_at], order: [ASC, DESC] }
        ) {
            edges {
                node {
                    ...recipeCardFields
                }
            }
        }
        allStrapiRecipeCategory(
            filter: { locale: { eq: $language }, parents: { elemMatch: { id: { eq: $recipeCategoryId } } } }
        ) {
            edges {
                node {
                    ...recipeCategoryFragment
                }
            }
        }
        currentRootCategory: strapiRecipeCategory(recipeCategoryId: { eq: $recipeCategoryId }, locale: { eq: $language }) {
            ...recipeCategoryFragment
        }
        meta: strapiMeta(metaId: { eq: $metaId }, locale: { eq: $language }) {
            ...metaFields
        }
    }
`;

export async function getServerData(context: GetServerDataProps) {
    const { query } = context;

    // @ts-ignore
    const language = context.pageContext.i18n.language;
    const recipes = await getRecipesByCategory(query as Partial<Record<'categories', string>>, language);

    return {
        props: {
            recipes,
            showRecipesFromServerData: recipes?.length > 0,
        },
    };
}

const getRecipesByCategory = async (query: Partial<Record<'categories', string>>, language: string) => {
    if (Object.keys(query).every((key) => !Object.keys(QUERY_PARAM_MAP).includes(key))) {
        return;
    }
    return fetchRecipes({ filter: { ...createFilterObjectFromQuery(query, QUERY_PARAM_MAP), _locale: language} });
};

const QUERY_PARAM_MAP = {
    kategoria: 'recipeCategories.slug',
};

export default RecipeListing;
