import React from 'react';

import {
    container,
    icon as iconClass,
    text,
    animatedTextHover,
    button,
} from './category-filter.module.scss';
import { IRecipeCategory } from '../../models/recipe-category.model';
import prependStrapiUrl from '../../utils/prepend-strapi-url';
import useQueryParam from '../../hooks/use-query-param';

import ToggleButton from '../atoms/toggle-button';

interface ICategoryFilter {
    className?: string;
    categories: IRecipeCategory[];
}

const CategoryFilter: React.FC<ICategoryFilter> = ({ className = '', categories }) => {
    const { appendValue, checkValue } = useQueryParam('kategoria');

    const handleClick = (slug: string) => () => appendValue(slug);

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className={`${container} ${className}`}>
            {categories.map(({ recipeCategoryId, title, slug, icon }) => (
                <ToggleButton
                    key={`recipe-category-filter-item-${recipeCategoryId}`}
                    active={checkValue(slug)}
                    onClick={handleClick(slug)}
                    className={button}
                >
                    <img
                        className={iconClass}
                        src={prependStrapiUrl(icon.url)}
                        alt={icon.alternativeText}
                    />
                    <span className={`${text} ${animatedTextHover}`}>{title}</span>
                </ToggleButton>
            ))}
        </div>
    );
};

export default CategoryFilter;
