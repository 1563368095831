import React from 'react';

import {
    button,
    active as activeClass,
    disabled as disabledClass,
} from './toggle-button.module.scss';

export interface IToggleButtonProps extends React.ComponentProps<'button'> {
    active: boolean;
}

const ToggleButton: React.FC<IToggleButtonProps> = ({
    active,
    children,
    className = '',
    disabled,
    ...props
}) => {
    return (
        <button
            className={`${button} ${active ? activeClass : ''} ${className} ${
                disabled ? disabledClass : ''
            }`}
            disabled={disabled}
            {...props}
        >
            {children}
        </button>
    );
};

export default ToggleButton;
